<template>
  <div class="rightSide">
    <div class="rightSide_inner">
      <div class="rightSide_inner_title">
        <p style="font-size: 30px; color: #333333;padding-left: 10px">
          {{$t("Display Sample Images") }}
          <span>  <el-button size="mini" round @click="$router.go(-1)">back</el-button> </span>
        </p>
      </div>
      <div class="content">
        <div class="images-list" v-if="imagesData.length > 0">
          <div class="images" v-for="(item,index) in imagesData" :key="index" @click="handleClickImage(item)">
            <el-tooltip v-if="item.filesrc" class="item" effect="dark" content="Download documentation" placement="top">
              <el-image style="width: 270px; height: 360px" title="Download documentation" class="banner-viewer" :src="item.imagesSrc" @click="handleDownFile(row)"></el-image>
            </el-tooltip>
            <el-image v-else :src="item.imagesSrc" fit="fill" style="width: 270px; height: 360px">
              <div slot="placeholder" class="image-slot">
                loading<span class="dot">...</span>
              </div>
            </el-image>
            <div class="images-title">{{ item.imagesName }}</div>
          </div>
        </div>
        <div class="images-list" v-else style="display: flex;align-items: center;justify-content: center">
          <div style="text-align: center;color: #8c939d">Loading Display Sample Images</div>
        </div>
      </div>
      <div style="text-align: center;clear: both" v-if="imagesData.length > 0">
        <el-pagination layout="total, prev, pager, next, jumper" :page-size="pageInfo.pagesize"
                       :total="pageInfo.total"
                       @current-change="handleChangeCurrentPage"
                       background>
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "images",
  data() {
    return {
      categoryid: 0,
      imagesData: [],
      pageInfo: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
    };
  },
  created() {

    const { categoryid }=this.$route.query;

    if (categoryid === undefined || categoryid === "" || categoryid === "0") {
      this.$router.push({ path: "/web/orderCenter/displaycatagory" });
    }

    this.categoryid=parseInt(categoryid);

    this.handleGetImages();

  },
  methods: {
    handleGetImages() {

      let params={
        page: this.pageInfo.page,
        pagesize: this.pageInfo.pagesize,
        categoryid: this.categoryid,
      };

      this.$axios.get("/w1/display/category/images", { params: params }).then(res => {
        const { data }=res.data;

        this.imagesData=data.data;
        this.pageInfo.total=data.total;

      });
    },
    handleRenewRenewSizeChange(val) {
      this.pageInfo.pagesize=val;
      this.handleGetImages();
    },
    handleChangeCurrentPage(val) {
      this.pageInfo.page=val;
      this.handleGetImages();
    },
    handleClickImage(row){
      if (row.filesrc){
        window.location.href = row.filesrc
      }else{
        sessionStorage.setItem("currentImage",JSON.stringify(row))

        this.$router.push({path:"/web/orderCenter/catagory/imagesInfo",query:{imagesid:row.id}})
      }
    },
    handleDownFile(row){
      window.location.href = row.filesrc
    }
  },
};
</script>

<style scoped lang="scss">
.rightSide {
  width: 1320px;
  margin: 15px 0 0 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  &_inner {
    width: 100%;
    height: 100%;

    &_title {
      width: 100%;
      height: 50px;
    }
  }
}

.table_style {
  width: 1218px;
  height: 68px;
  margin-top: 30px;
}

.footer {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.images-list {
  min-height: 800px;
  .images {
    width: 320px;
    height: 460px;
    float: left;
    padding: 10px;
  }
  .images-title{
    text-align: center;
    padding: 10px;
  }
}

</style>
